#modal-company-terms {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    height: 90vh;
}

.modal-terms-content {
    height: 90%;
    overflow-y: scroll;
}

.modal-terms-footer {
    height: 10%;
}

